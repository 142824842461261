<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Container',
  components: {},
  data() {
    return {
      lang: 'he',
    };
  },
  computed: {
    ...mapGetters({
      isDesktopApp: 'GET_BONAUF_DESKTOP_APP',
    }),
  },
  mounted() {
    const accessibility = document.querySelector('.accessibility_component > a.btn_accessibility.accessibility_component');
    if (accessibility && !this.isDesktopApp) accessibility.style.display = 'none';
  },
};
</script>

<style>
</style>
